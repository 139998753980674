/* eslint-disable lines-between-class-members */
class QuestionDTO {
  _id;
  taskId;
  type;
  isPictureMandatory;
  question;
  lastModifiedBy;
  lastModifiedAt;
  label;
  findingsByInspector;
  yesOrNo;
  multipleChoices;
  multipleChoicesValues;
  inputValue;
  dimensions;
  tableColumn;
  tableRow;
  tableImg;
  tableData;
  checked;
  image;

  constructor(data) {
    this._id = data._id;
    this.taskId = data.taskId;
    this.type = data.type;
    this.isPictureMandatory = data.isPictureMandatory;
    this.question = data.question;
    this.lastModifiedBy = data.lastModifiedBy;
    this.lastModifiedAt = data.lastModifiedAt;
    this.label = data.label;
    this.findingsByInspector = data.findingsByInspector;
    this.yesOrNo = data.yesOrNo;
    this.multipleChoices = data.multipleChoices;
    this.multipleChoicesValues = data.multipleChoicesValues;
    this.inputValue = data.inputValue;
    this.dimensions = data.dimensions;
    this.tableColumn = data.tableColumn;
    this.tableRow = data.tableRow;
    this.tableImg = data.tableImg;
    this.tableData = data.tableData;
    this.checked = data.checked;
    this.image = data.image || [];
  }
}

module.exports = QuestionDTO;
